import React from 'react';

function Dashboard() {
    return (
        <>
            <div className='container'>
                dashboard
            </div>
        </>
    );
}

export default Dashboard;