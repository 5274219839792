import React from 'react';

function Register() {
    return (
        <>
            <div style={{display: 'grid', width: '100%'}}>
                sign up
            </div>
        </>
    );
}

export default Register;