import React from 'react';

function About() {
    return (
        <>
            <div className='container'>
                about
            </div>
        </>
    );
}

export default About;